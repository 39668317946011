import { Fragment, useState, useEffect } from 'react'
import { Avatar, Box, Container, Typography, useTheme } from '@mui/material'
import EmmaImage from '../resources/emma.jpg'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import EmailIcon from '@mui/icons-material/Email'
import { education, experiences, skills, certificates, references } from './data'

function App() {
  const theme = useTheme()
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  const mobile = window.innerWidth < 768

  useEffect(() => {
    window.onresize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
  }, [])
  
  return (
    <Fragment>
      <Box sx={{
        backgroundColor: 'info.dark'
      }}>
        <Container sx={{
          display: mobile ? 'block' : 'flex',
          alignItems: 'center',
          pt: 4,
          pb: 4
        }}>
          <Box sx={{pr: !mobile && 8}}>
            <Avatar
              alt="Emma Avatar"
              src={EmmaImage}
              sx={{ width: 256, height: 256 }}
            />
          </Box>
          <Box>
            <Typography variant={mobile ? "h2" : "h1"} sx={{color: 'primary.contrastText'}}>Emma Shih</Typography>
            <Typography variant="h4" sx={{color: 'primary.contrastText'}}>Web Developer</Typography>
            <Box>
              <Box sx={{display: "flex", cursor: 'pointer', alignItems: 'center'}}>
                <Box sx={{color: 'primary.light', mt: 1, mr: 1, mb: 1}}><PhoneIphoneIcon /></Box>
                <a href="tel:+(858)842-0582"><Typography variant="subtitle1" sx={{color: 'primary.contrastText'}}>(858)842-0582</Typography></a>
              </Box>
              <Box sx={{display: "flex", cursor: 'pointer', alignItems: 'center'}}>
                <Box sx={{color: 'primary.light', mr: 1}}><EmailIcon /></Box>
                <a href="mailto:emmashih15@gmail.com"><Typography variant="subtitle1" sx={{color: 'primary.contrastText'}}>emmashih15@gmail.com</Typography></a>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Container sx={{
        pb: 2
      }}>
        <Box sx={{display: "flex", borderBottom: "2px solid", borderColor: "primary.light", mt: 3}}>
          <Typography variant="h5" sx={{color: "primary.light"}}>About</Typography>
        </Box>
        <Box sx={{mt: 2}}>
          <Typography variant="body1">
              Hello, my name is Emma Shih. This website serves as my resume to show off my skills in Reactjs to those interested. 
            I was a math student in College but decided that my passion was in Web Development. 
            I have completed one paid internship in the Web Development and App development field.
            I am looking to grow my career and find a company I can call home.
            I am a hard worker and a quick learner. Thank you for taking the time to review my resume.
          </Typography>
        </Box>
        <Box sx={{display: "flex", borderBottom: "2px solid", borderColor: "primary.light", mt: 3}}>
          <Typography variant="h5" sx={{color: "primary.light"}}>Education</Typography>
        </Box>
        <Box sx={{display: mobile ? "block" : "flex"}}>
          {education && education.map((school, key) => <Box key={`education-${key}`} sx={{width: mobile ? "100%" : "50%", mt: mobile && 2}}>
            <Typography variant="h6">{school.degree}</Typography>
            <Typography variant="h6">{school.dates}</Typography>
            <Typography variant="body1">{school.title}</Typography>
            <Typography variant="body1">{school.location}</Typography>
          </Box>)}
        </Box>
        {mobile
          ? <Fragment>
            <Box sx={{display: "block", borderBottom: "2px solid", borderColor: "primary.light", mt: 3}}>
              <Typography variant="h5" sx={{color: "primary.light", width: "100%"}}>Skills</Typography>
            </Box>
            <Box sx={{display: "block"}}>
              <Box sx={{display: "block"}}>
                {skills && skills.map((skill, key) => <Typography key={`certificate-${key}`} sx={{ mt: 1}} variant="body1"> -{skill}</Typography>)}
              </Box>
            </Box>
            <Box sx={{display: "block", borderBottom: "2px solid", borderColor: "primary.light", mt: 3}}>
              <Typography variant="h5" sx={{color: "primary.light", width: "100%"}}>Experience</Typography>
            </Box>
            <Box sx={{display: "block"}}>
              <Box sx={{width: "100%"}}>
                {experiences && experiences.map((exp, key) => <Box key={`experience-${key}`} sx={{mt:1}}>
                  <Typography variant="h6">{exp.title}</Typography>
                  <Typography variant="body1">{exp.description}</Typography>
                </Box>)}
              </Box>
            </Box>
          </Fragment>
          : <Fragment>
            <Box sx={{display: "flex", borderBottom: "2px solid", borderColor: "primary.light", mt: 3}}>
              <Typography variant="h5" sx={{color: "primary.light", width: "50%"}}>Experience</Typography>
              <Typography variant="h5" sx={{color: "primary.light", width: "50%"}}>Skills</Typography>
            </Box>
            <Box sx={{display: "flex"}}>
              <Box sx={{width: "50%", pr: 2}}>
                {experiences && experiences.map((exp, key) => <Box key={`experience-${key}`} sx={{mt:1}}>
                  <Typography variant="h6">{exp.title}</Typography>
                  <Typography variant="body1">{exp.description}</Typography>
                </Box>)}
              </Box>
              <Box sx={{display: "flex", width: "50%", flexWrap: "wrap"}}>
                {skills && skills.map((skill, key) => <Typography key={`certificate-${key}`} sx={{width: "50%", mt: 1}} variant="body1"> -{skill}</Typography>)}
              </Box>
            </Box>
          </Fragment>
        }
        {mobile
          ? <Fragment>
            <Box sx={{display: "block", borderBottom: "2px solid", borderColor: "primary.light", mt: 3}}>
              <Typography variant="h5" sx={{color: "primary.light", width: "100%"}}>Certifications</Typography>
            </Box>
            <Box sx={{width: "100%"}}>
                {certificates && certificates.map((cert, key) => <Box key={`certificate-${key}`} sx={{mt: 1}}>
                  <Typography variant="h6">{cert.title}</Typography>
                  <a href={cert.description} target="_blank" rel="noreferrer"><Typography variant="body1">{cert.description}</Typography></a>
                </Box>)}
            </Box>
            <Box sx={{display: "block", borderBottom: "2px solid", borderColor: "primary.light", mt: 3}}>
              <Typography variant="h5" sx={{color: "primary.light", width: "100%"}}>References</Typography>
            </Box>
            <Box sx={{width: "100%"}}>
                {references && references.map((ref, key) => <Box key={`reference-${key}`} sx={{mt: 1}}>
                  <Typography variant="h6">{ref.name}</Typography>
                  <Typography variant="body1">{ref.title}</Typography>
                  {ref.address && <Typography variant="body1">{ref.address}</Typography>}
                  <Typography variant="body1">{ref.email}</Typography>
                </Box>)}
            </Box>
          </Fragment>
          : <Fragment>
            <Box sx={{display: "flex", borderBottom: "2px solid", borderColor: "primary.light", mt: 3}}>
              <Typography variant="h5" sx={{color: "primary.light", width: "50%"}}>Certifications</Typography>
              <Typography variant="h5" sx={{color: "primary.light", width: "50%"}}>References</Typography>
            </Box>
            <Box sx={{display: "flex"}}>
              <Box sx={{width: "50%", pr: 2}}>
                {certificates && certificates.map((cert, key) => <Box key={`certificate-${key}`} sx={{mt: 1}}>
                  <Typography variant="h6">{cert.title}</Typography>
                  <a href={cert.description} target="_blank" rel="noreferrer"><Typography variant="body1">{cert.description}</Typography></a>
                </Box>)}
              </Box>
              <Box sx={{width: "50%"}}>
                {references && references.map((ref, key) => <Box key={`reference-${key}`} sx={{mt: 1}}>
                  <Typography variant="h6">{ref.name}</Typography>
                  <Typography variant="body1">{ref.title}</Typography>
                  {ref.address && <Typography variant="body1">{ref.address}</Typography>}
                  <Typography variant="body1">{ref.email}</Typography>
                </Box>)}
              </Box>
            </Box>
          </Fragment>
        }
      </Container>
    </Fragment>
  )
}

export default App
