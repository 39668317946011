export const education = [
    {
        degree: "High School Diploma",
        dates: "2016-2020",
        title: "Canyon Crest Academy",
        location: "San Diego, CA"
    },
    {
        degree: "Mathematics",
        dates: "2021-2022",
        title: "Ohio State University",
        location: "Columbus, OH"
    },
]

export const experiences = [
    {
        title: "PalletGeo | October 2022 - Current | Web Developer Internship",
        description: "Worked on a React Native application, creating authentication screens and user account screens. Worked in two week sprints to deliver features to project manager."
    },
]

export const skills = [
    "Javascript ES5 - ESNext/ES6/ESX",
    "Nodejs",
    "CSS/SCSS/Styled-Components/Emotion",
    "HTML5^",
    "Reactjs Class/Functional(Hooks)",
    "GraphQL/Apollo",
    "Redux",
    "Typescript",
    "Firebase",
    "Webpack/Babel",
    "Docker",
    "Redis",
    "AWS",
    "Git/Github",
    "React Native"
]

export const certificates = [
    {
        title: "Complete React Developer 2022",
        description: "https://www.udemy.com/course/complete-react-developer-zero-to-mastery/"
    },
    {
        title: "The Complete Junior to Senior Web Developer Roadmap",
        description: "https://www.udemy.com/course/the-complete-junior-to-senior-web-developer-roadmap/"
    }
]

export const references = [
    {
        name: "Ross Harrison | Pallet Geo",
        title: "Web UI Manager",
        address: null,
        email: "support@palletgeo.com"
    },
    {
        name: "Ian Hess | Trane Technologies",
        title: "Senior UI Architect",
        address: null,
        email: "ian@tranetechnologies.com | 612-772-5983"
    },
]